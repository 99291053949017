/* 
body{
    font-family: Georgia, 'Times New Roman', Times, serif;
} */
@font-face {
  font-family: "Mylon";
  src: url("../public/assests/font/mylon-bolditalic.woff2")
      url("../public/assests/font/mylon-bolditalic.woff") format("woff"),
    url("../public/assests/font/mylon-bolditalic.otf") format("opentype");
}

.plastic-heading {
  font-family: "Mylon", sans-serif !important;
}

.custom-list {
  list-style-type: circle; /* Removes the default list style */
  padding-left: 1.5rem; /* Adjusts the indentation */
}
.custom-list li::marker {
  width: 10px; /* Adjust the width of the circle */
  height: 10px;
  font-size: 20px;
}

@font-face {
  font-family: "TTNorms";
  font-weight: 400;
  src: url("../public/assests/font/TTNorms-Regular.otf") format("truetype");
}

@font-face {
  font-family: "TTNorms2";
  font-weight: 800;
  src: url("../public/assests/font/TTNorms-Black.otf") format("truetype");
}

/* @font-face {
  font-family: "TTNorms";
  font-weight: 700;
  src: url("../public/assests/font/TTNorms-Black.otf") format("truetype");
} */


.tt-norms {
  font-family: "TTNorms" !important;
}

.tt-norms-bold {
  font-family: "TTNorms2" !important;
}

.custom-table .ant-table-bordered {
  overflow: auto !important;
}

* {
  box-sizing: border-box;
  font-family: "TTNorms" !important;
}

.end-meeting .ant-form-item-required::before {
  content: "" !important;
}

.custom-form .ant-form-item-row {
  width: 100% !important;
}

.custom-card .ant-card-body {
  padding: 0px !important;
}

.custom-card {
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.custom-card:hover {
  transform: scale(1.05) !important;
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1), 0 4px 6px rgba(0, 0, 0, 0.1) !important;
}

.bg-image-fact {
  background-image: url("../public/assests/plastic/nJbkPO.png");
  background-size: contain;
  background-position: center;
}

.bg-image-fact::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.562);
}

.custom-collapse {
  background-color: #f7f7f7;
  border-radius: 8px;
}

.panel-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  cursor: pointer;
}

.icon {
  font-size: 20px;
}

/* price-details page - start */
.price-details-page {
  width: 100%;
  display: flex;
  justify-content: center;
}

.bg-contact-image {
  background-image: url("../public/assests/contact-us.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 50vh;
}

.review-main {
  overflow-x: auto;
  transition: overflow 0.3s ease;
  /* Smooth transition */
}

.form-control {
  width: 100% !important;
}

.review-main::-webkit-scrollbar {
  width: 20px;
  height: 10px !important;
}

/* Track */
.review-main::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
.review-main::-webkit-scrollbar-thumb {
  background: #030121;
  border-radius: 10px;
}

/* Handle on hover */
.review-main::-webkit-scrollbar-thumb:hover {
  background: #000949;
}

.price-details-page .price-details {
  margin: 3.5vw 0 7vw 0;
  width: 80vw;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 0.7rem;
  padding: 1.8rem 1.8rem;
  display: flex;
  flex-direction: column;
  gap: 1.8rem;
  /* gap between different sections */
}

.price-details-page .service {
  display: flex;
  gap: 3.2rem;
  border-radius: 0.8rem;
  /* border: .1rem solid #ccc; */
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 0.5rem 0.8rem;
}

.price-details-page .service .image {
  width: 35%;
  aspect-ratio: 1.5;
  position: relative;
}

.price-details-page .service .image::after {
  content: "";
  position: absolute;
  right: -1.6rem;
  top: 50%;
  transform: translateY(-50%);
  width: 0.15rem;
  height: 90%;
  background-color: #ccc;
}

.price-details-page .service .image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 1.3rem;
}

.price-details-page .service .box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.4rem;
  font-size: 1.1rem;
  padding: 0.7rem 0;
  color: #111;
}

.price-details-page .service .box .contact {
  font-weight: 600;
}

.price-details-page .service .box .contact-details {
  display: flex;
  background: #e7e7e7;
  /* gap: 0.8rem; */
  padding: 0.18rem 0.5rem;
  border-radius: 0.3rem;
}

.price-details-page .service .box .contact-details div:first-child {
  border-right: 0.1rem solid #ccc;
  padding: 0 0.4rem;
}

.price-details-page .service .box .title {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 0.3rem;
  color: #000;
}

.price-details-page .total {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
}

.price-details-page .total .payment-image {
  display: flex;
  justify-content: center;
  align-items: center;
}

.price-details-page .total .payment-image img {
  width: 50%;
}

.price-details-page .total .heading {
  font-size: 1.5rem;
  font-weight: 600;
}

/* .price-details-page .total .table {
  display: flex;
  flex-direction: column;
  font-size: 1rem;
}
.price-details-page .total .table table {
  table-layout: fixed;
  border-radius: 1rem;
  border-collapse: collapse;
}
.price-details-page .total .table td {
  border: .1rem solid #ccc;
  width: 50%;
  padding: .4rem 0.8rem;
}
.price-details-page .total .table tr:last-child {
  background: #dfdfdf;
} */
.price-details-page .total .table {
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  background: #f0f0f0;
  border-radius: 1rem;
  overflow: hidden;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.price-details-page .total .table table {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
}

.otp-main-num input::-webkit-outer-spin-button,
.otp-main-num input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.price-details-page .total .table td {
  border: 1px solid #bbb;
  padding: 0.7rem 1rem;
  text-align: left;
  color: #333;
}

.price-details-page .total .table tr:nth-child(even) td {
  background: #f6f6f6;
}

.price-details-page .total .table tr:hover td {
  background: #e3e3e3;
  color: #000;
}

.price-details-page .total .table tr:last-child td {
  background: #cccccc;
  font-weight: bold;
}

.price-details-page .total .description {
  background: #dfdfdf;
  border-radius: 0.8rem;
  padding: 0.6rem 1rem;
}

.main-container2 .ant-spin-nested-loading {
  width: 100% !important;
}

.price-details-page .total .button-container {
  display: flex;
  justify-content: flex-end;
}

.price-details-page .total .button {
  width: fit-content;
  background: #333;
  color: #fff;
  padding: 0.5rem 0.7rem;
  font-size: 1rem;
  font-weight: 500;
  border-radius: 0.4rem;
  display: flex;
  gap: 0.3rem;
  align-items: center;
}

/* price-details page - start */
.price-details-page {
  width: 100%;
  display: flex;
  justify-content: center;
}

.price-details-page .price-details {
  margin: 5vw 0 7vw 0;
  width: 80vw;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 0.7rem;
  padding: 1.8rem 1.8rem;
  display: flex;
  flex-direction: column;
  gap: 1.8rem;
  /* gap between different sections */
}

.price-details-page .service {
  display: flex;
  gap: 3.6rem;
  gap: clamp(2.5rem, 4.6vw, 4rem);
  border-radius: 0.8rem;
  /* border: .1rem solid #ccc; */
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 0.8rem;
  align-items: center;
}

.price-details-page .service .image {
  width: 35%;
  height: 90%;
  aspect-ratio: 1.5;
  position: relative;
}

.price-details-page .service .image::after {
  content: "";
  position: absolute;
  right: -1.6rem;
  right: clamp(-2rem, -2.3vw, -1.25rem);
  top: 50%;
  transform: translateY(-50%);
  width: 0.15rem;
  height: 90%;
  background-color: #ccc;
}

.price-details-page .service .image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0.9rem;
}

.price-details-page .service .box {
  width: 65%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.4rem;
  font-size: 1.1rem;
  padding: 0.7rem 0;
  color: #111;
}

.price-details-page .service .box .contact-details {
  display: flex;
  background: #e7e7e7;
  /* gap: 0.8rem; */
  padding: 0.18rem 0.5rem;
  border-radius: 0.3rem;
}

.price-details-page .service .box .contact-details div:first-child {
  border-right: 0.1rem solid #ccc;
  padding: 0 0.4rem;
}

.price-details-page .service .box .title {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 0.3rem;
  color: #000;
}

.price-details-page .service .box div {
  display: flex;
  gap: 0.4rem;
  /* align-items: center; */
}

.price-details-page .service .box div:last-child {
  align-items: center;
}

.price-details-page .service .box div .icon {
  display: inline-block;
  font-size: 1.5rem;
  margin-top: 0.2rem;
}

.price-details-page .service .box div:last-child .icon {
  margin-top: 0;
}

.price-details-page .total {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
}

.price-details-page .total .payment-image {
  display: flex;
  justify-content: center;
  align-items: center;
}

.price-details-page .total .payment-image img {
  width: 50%;
}

.price-details-page .total .heading {
  font-size: 1.5rem;
  font-weight: 600;
}

/* .price-details-page .total .table {
  display: flex;
  flex-direction: column;
  font-size: 1rem;
}
.price-details-page .total .table table {
  table-layout: fixed;
  border-radius: 1rem;
  border-collapse: collapse;
}
.price-details-page .total .table td {
  border: .1rem solid #ccc;
  width: 50%;
  padding: .4rem 0.8rem;
}
.price-details-page .total .table tr:last-child {
  background: #dfdfdf;
} */
.price-details-page .total .table {
  display: flex;
  flex-direction: column;
  font-size: 1rem;
  background: #f0f0f0;
  border-radius: 1rem;
  overflow: hidden;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.price-details-page .total .table table {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
}

.price-details-page .total .table td {
  border: 1px solid #bbb;
  padding: 0.7rem 1rem;
  text-align: left;
  color: #333;
}

.price-details-page .total .table tr:nth-child(even) td {
  background: #f6f6f6;
}

.price-details-page .total .table tr:hover td {
  background: #e3e3e3;
  color: #000;
}

.price-details-page .total .table tr:last-child td {
  background: #cccccc;
  font-weight: bold;
}

.price-details-page .total .description {
  background: #dfdfdf;
  border-radius: 0.8rem;
  padding: 0.6rem 1rem;
}

.price-details-page .total .button-container {
  display: flex;
  justify-content: flex-end;
}

.price-details-page .total .button {
  width: fit-content;
  background: #333;
  color: #fff;
  padding: 0.7rem 1rem;
  font-size: 1.1rem;
  font-weight: 500;
  border-radius: 0.5rem;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  margin-top: 0.3rem;
}

/* PaymentDone Page - Start */
.payment-done {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.payment-done .container {
  width: 80vw;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  border-radius: 0.7rem;
  margin: 2.5rem 0;
  padding: 1.5rem;
}

.payment-done .container .image {
  width: 30%;
  max-width: 350px;
  aspect-ratio: 1;
}

.payment-done .container .image img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.payment-done .container .title {
  font-size: 1.3rem;
  font-weight: 500;
  text-align: center;
}

.payment-done .container .description {
  font-weight: 1rem;
  color: #333;
  text-align: center;
}

.payment-done .container .box {
  background: #f6e58d;
  padding: 0.4rem 1rem;
  border-radius: 0.7rem;
  border: 0.1rem solid #ffbe76;
  margin: 0.7rem 0;
}

.payment-done .container .box div {
}

.payment-done .container .button {
  width: fit-content;
  background: #333;
  color: #fff;
  padding: 0.4rem 0.9rem;
  border-radius: 0.4rem;
}

/* oncology - fixes */
.oncology-vascular p,
.oncology-vascular li {
  font-size: clamp(1rem, 1.35vw, 1.4rem) !important;
}

.glassmorphic {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 16px;
  padding: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.7);
}

.blur-effect {
  filter: blur(10px);
  -webkit-filter: blur(10px);
}

.glass-effect {
  background: linear-gradient(
    90deg,
    rgba(0, 89, 176, 0.3),
    rgba(10, 186, 181, 0.3)
  );
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid hsla(0, 0%, 100%, 0.3);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* translate-icon */
.translate-icon {
  width: 34px;
  height: 34px;
  background: url("../public/translation.png") no-repeat center center;
  background-size: contain;
  cursor: pointer;
  /* position: absolute;
  top: 1.3vh;
  right: 8vw; */
}

/* @media (max-width: 1200px) {
  .glassmorphic {
    backdrop-filter: none;
    -webkit-backdrop-filter: none;
    }
    } */

@media (max-width: 900px) {
  .payment-done .container .image {
    width: 35%;
  }

  .price-details-page .price-details {
    width: 90vw;
  }

  .price-details-page .service {
    margin: 0 -0.6rem;
  }

  .price-details-page .service .box {
    width: 60%;
  }

  .price-details-page .service .image {
    width: 40%;
  }

  .payment-done .container .title {
    font-size: 1.1rem;
  }

  .payment-done .container .description {
    font-weight: 0.9rem;
  }
}

@media (max-width: 768px) {
  .price-details-page .service {
    flex-direction: column;
  }

  .price-details-page .service .box {
    width: 90%;
  }

  .price-details-page .service .image {
    width: 85%;
  }

  .price-details-page .service .image::after {
    right: unset;
    top: unset;
    height: 0.15rem;
    width: 115%;
    left: 50%;
    transform: translateX(-50%);
    bottom: -2rem;
  }

  .price-details-page .service .box .contact-details {
    flex-wrap: wrap;
  }

  /* oncology - fixes */
  .oncology-vascular p,
  .oncology-vascular li {
    font-size: clamp(1rem, 2.4vw, 1.4rem) !important;
  }
}

/* react - player */

.player-react-sm video {
  height: fit-content !important;
}

.player-react video {
  object-fit: contain !important;
  /* width: 100% !important;
  height: 100% !important; */
}


@media (max-width: 450px) {
  .player-react video {
    height: fit-content !important;
  }
}

.istanbul {
  top: 50%;
  left: 52%;
}
.bangkok {
  top: 15%;
  left: 50%;
}
.miami {
  left: 70%;
  top: 10%;
}
.riyadh {
  top: 15%;
  left: 27%;
}
.dubai {
  left: 36%;
  top: 3%;
}

.mexico {
  top: 20%;
  left: 60%;
}

.saopaulo {
  top: 32%;
  left: 46%;
}

.rio-de-janerio {
  top: 26%;
  left: 70%;
}
.shangai {
  top: 53%;
  left: 78%;
}

.seoul {
  top: 12%;
  left: 18%;
}

@media (max-width: 785px) {
  .miami {
    left: 70%;
    top: 8%;
  }
  .shangai {
    top: 38%;
    left: 76%;
  }
  .rio-de-janerio {
    top: 18%;
    left: 70%;
  }
  .bangkok {
    top: 11%;
    left: 50%;
  }
  .mexico {
    top: 13%;
    left: 62%;
  }
  .saopaulo {
    top: 24%;
    left: 49%;
  }
  .istanbul {
    top: 35%;
    left: 51%;
  }
  .dubai {
    left: 36%;
    top: 5%;
  }
}
@media (max-width: 400px) {
  .miami {
    left: 70%;
    top: 8%;
  }
  .shangai {
    top: 38%;
    left: 73%;
  }
  .rio-de-janerio {
    top: 18%;
    left: 70%;
  }
  .bangkok {
    top: 11%;
    left: 50%;
  }
  .mexico {
    top: 13%;
    left: 62%;
  }
  .saopaulo {
    top: 24%;
    left: 49%;
  }
  .istanbul {
    top: 35%;
    left: 51%;
  }
  .dubai {
    left: 36%;
    top: 5%;
  }
}
